.poster-heading {
    text-align: center;
    padding-top: 20px;
}

.poster-heading h2 {
    text-align: center;
    color: chocolate;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* .poster-heading h2:hover {
    color: #291680;
} */

.poster-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.poster-card {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-width: 400px;
    height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.poster-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .poster-card-container {
        justify-content: center;
        /* Center the single slide */
        overflow-x: scroll;
        align-items: center;
    }

    .poster-card {
        max-width: 65%;
        max-height: 80%;
    }

    .carousel .slide {
        flex: 0 0 75%;
        max-width: 90%;
        margin-left: -20%;
    }
}

@media screen and (max-width: 1000px) and (orientation:landscape) {
    .poster-card-container {
        justify-content: center;
        /* Center the single slide */
        overflow-x: scroll;
        align-items: center;
    }

    .poster-card {
        max-width: 65%;
        max-height: 80%;
    }

    .carousel .slide {
        flex: 0 0 95%;
        max-width: 50%;
        margin-left: -10%;
    }

}
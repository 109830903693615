.contact-container {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-heading {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 32px;
    color: #1b495e;
}

.form-group {
    margin-bottom: 15px;
}

label {
    font-size: 18px;
    color: #333;
    display: block;
}

.form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ccc;
    /* Add an underline */
    outline: none;
    /* Remove default outline */
    background: transparent;
    /* Make background transparent */
}

.form-label {
    font-size: 18px;
    color: #333;
    display: block;
    margin-bottom: 5px;
    /* Add some space below the label */
}

.submit-button {
    background-color: #000000;
    color: #fff;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #ff7f27;
}

.address-container {
    background-color: #f9f9f9;
    padding: 20px;
    margin: 20px auto;
    max-width: 400px;
    border-radius: 10px;
}

.address-heading {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.address-details {
    font-size: 18px;
    color: #555;
}

.address-details p {
    margin-bottom: 10px;
}

.flex-container {
    display: flex;
    justify-content: center;
    margin: 10px;
    /* Add this line to remove the extra space */
}

.contact-container,
.address-container {
    flex: 0 0 calc(50% - 10px);
    box-sizing: border-box;
    margin: 10px;
    /* Add this line to add some space around each container */
}

.address-details svg {
    margin-right: 8px;
    color: #ff7f27;
    /* Add some space between the icon and text */
}

@media (max-width: 767px) {
    .flex-container {
        display: grid;
        justify-content: center;
        margin: 5px;
        /* Add this line to remove the extra space */
    }
}

.main-about {
    overflow-x: hidden;
}

@media (max-width: 290px) and (orientation:portrait) {

    .h2text {
        font-size: 18px;
    }

    .h2para {
        font-size: 15px;
    }
}




/* @media (min-width: 600px) and (max-width: 680px) and (orientation:landscape) {
    .abouttext {
    }
} */



/* @media (min-width: 993px) and (max-width: 1050) {
    .abouttext {
        position: relative;
        left: 38%;
    }
} */

.container-about {
    /* background-color: #555; */
    text-align: center;
    width: 82%;
    justify-content: center;
    background: #ffffff;
    margin: auto;
    color: #000000;
    font-size: x-large;
    font-style: italic;
    padding-bottom: 25px;
    padding-top: 10px;
    margin-top: -130px;
    border: 3px solid #ff7f27;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .container-about {
        padding: 15px;
        border: 2px solid #ff7f27;
        width: 100%;
    }
}


.h2text {
    color: #583a88;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: underline;
}

.h2para {
    margin-left: 10px;
    margin-right: 10px;
}

.bgimg {
    background-image: url("./gray.jpg");
    width: 100%;
    height: 300px;
    text-align: center;
    justify-content: center;
    margin: auto;
}



.counter {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 250px;
    margin: 10px;
    font-size: 16px;
    left: 27%;
    border: 2px solid #ff7f27;
    margin-bottom: 20px;
}

/* @media (min-width: 600px) and (max-width: 680px) and (orientation:landscape) {
    body.counter {
        display: inline-block;
        width: 30%;
        left: 1%;
        margin: 1px;
        margin-bottom: 5%;
    }
} */

@media (max-width: 290px) and (orientation:portrait) {
    body .counter {
        margin-left: -7px;
        width: 70%;
    }

    .logotext {
        font-size: 5px;
    }

}

@media (min-width:645px) and (max-width: 670px) and (orientation:landscape) {
    .counter {
        max-width: 33%;
    }
}

@media (min-width: 500px) and (max-width: 550px) and (orientation:portrait) {
    body .counter {
        margin-left: -7px;
        width: 70%;
    }

    .logotext {
        font-size: 5px;

    }
}






@media (max-width: 767px) {
    .counter {
        left: 17%;
        margin-bottom: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .counter {
        margin: auto;
        margin-right: 12px;
        margin-bottom: 20px;
        display: inline-block;
        left: 3%;
        width: 30%;
    }

}

@media (min-width: 992px) and (max-width: 1200px) {
    .counter {
        margin: auto;
        margin-right: 12px;
        margin-bottom: 20px;
        display: inline-block;
        left: 3%;
        width: 30%;
    }
}

@media (min-width: 1201px) and (max-width: 1299px) {
    .counter {
        left: 17%;
    }
}

@media (min-width: 1300px) and (max-width: 1400px) {
    .counter {
        left: 20%;
    }
}

@media (min-width: 1410px) and (max-width: 1600px) {
    .counter {
        left: 320px;
    }
}

@media (min-width: 2000px) {
    .counter {
        left: 870px;
    }

    .card-container {
        margin-right: 200px;
    }
}



.counter h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.counter h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0;
}

.fticon {
    color: #583a88;
}

@media (min-width:645px) and (max-width: 670px) and (orientation:landscape) {
    .counter h2 {
        margin-top: 5px;
        font-size: 15px;
    }
}

.counterval {
    color: #583a88;
}

.logomain {
    width: 20%;
    height: 20%;
    padding: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 40%;
}



@media (max-width: 767px) {
    .logomain {
        width: 90%;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 5%;
        padding-bottom: 25px;
    }
}

@media (max-width: 767px) and (orientation:landscape) {
    .logomain {
        width: 50%;
        margin-left: 18%;
    }

    body .counter {
        display: inline-block;
        width: 32%;
        left: 1%;
        margin: 2px;
        margin-bottom: 5%;
    }

}


@media (min-width: 600px) and (max-width: 680px) and (orientation:landscape) {
    .logomain {
        width: 50%;
        margin: auto;
        margin-left: 18%;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .logomain {
        width: 40%;
        left: 30%;
    }
}

.logotext {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #7a0d31;
    text-align: center;
    padding: 10px;
    font-size: xx-large;
    padding-bottom: 20px;
    font-style: italic;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

@media (min-width: 300px) and (max-width: 499px) and (orientation:portrait) {
    .logotext {
        font-size: large;
    }
}

.logocontainer {
    align-items: center;
    margin: 20px;
    border: 1px solid #555;
    box-shadow: 5px 5px 12px 2px #555;
}

@media (max-width: 290px) and (orientation:portrait) {
    .logotext {
        font-size: 20px;
    }

    /* .logomain {
        margin-left: 10px;
    } */
}

/* product */

.d-flex {
    padding: 20px;
    position: relative;
    width: 90%;
}

.btn-outline-success:hover {
    background-color: #000000;
    border: #ffffff;
    color: #fff;
}


@media (max-width: 400px) and (orientation: portrait) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 12.5%;
    }
}

@media (min-width:510px) and (max-width: 550px) and (orientation: portrait) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 22.5%;
    }
}


@media (min-width:401px) and (max-width: 450px) and (orientation: portrait) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 15.5%;
    }
}



@media (max-width: 700px) and (orientation: landscape) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 2%;
    }
}

@media (min-width: 700px) and (max-width: 900px) and (orientation: portrait) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 12%;
    }
}


@media (min-width: 701px) and (max-width: 800px) and (orientation: landscape) {

    /* iphone12pro */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10.5%;
    }
}

@media (min-width: 801px) and (max-width: 900px) and (orientation: landscape) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 15.5%;
    }
}


@media (min-width: 769px) and (max-width: 900px) and (orientation: portrait) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 14%;
    }
}

@media (min-width: 901px) and (max-width: 1000px) and (orientation: landscape) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 17%;
    }
}

@media (min-width: 1001px) and (max-width: 1100px) and (orientation: landscape) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 21%;
    }
}

@media (min-width: 901px) and (max-width: 1000px) and (orientation: portrait) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 17%;
    }
}

@media (min-width: 1101px) and (max-width: 1200px) and (orientation: landscape) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 11.5%;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) and (orientation: landscape) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 6%;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) and (orientation: landscape) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 15%;
    }
}

@media (min-width: 1401px) and (max-width: 1550px) {

    /* (s20ultra) */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10.5%;
    }
}

@media (min-width: 1551px) {
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10%;
    }
}


.card {
    max-height: 400px;
    border-radius: 10px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
    margin-right: 20px;
    overflow: hidden;
    box-shadow: 10px 10px 5px rgb(185, 193, 196);
}

.card img {
    max-height: 60%;
    max-width: 70%;
    margin: auto;
    border-radius: 10px;
}

.card-text {
    font-size: small;
    font-family: 'Times New Roman', Times, serif;
    color: #000000;
    font-weight: bold;
    text-align: center;
}


.card-title {
    background-color: #1c105e;
    color: #fff;
    text-align: center;
    border-radius: 10px;
}


.heading {
    background-color: #ffffff;
    border: 2px solid coral;
    width: 70%;
    height: 50px;
    margin: auto;
    margin-top: 90px;
    margin-bottom: 30px;
    border-radius: 10px;
}

@media (min-width: 1450px) and (max-width: 2001px) {
    .heading {
        margin-top: 5%;
    }
}




.heading h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder;
    color: #1b495e;
    text-align: center;
    margin: auto;
    margin-top: 7.5px;
}

.card-body {
    background-color: #dbd3cd;
    max-height: 160px;
}

.icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* Add some horizontal spacing between icons */
}

.ellogo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 10px;
    transition: transform 0.2s;
    margin: 0 50px;
}

.ellogo1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    /* Set a specific width */
    height: 170px;
    /* Set a specific height */
    object-fit: cover;
    /* Maintain aspect ratio */
    /* Add some spacing between images */
    margin-top: 15px;
    transition: transform 0.2s;
    margin-right: 40px;
}


.ipotext {
    color: #583a88;
    font-style: italic;
    text-align: center;
    position: relative;
    right: 20px;
    bottom: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.ipotext1 {
    color: #583a88;
    font-style: italic;
    text-align: center;
    position: relative;
    top: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.ellogo:hover {
    transform: scale(1.1);
}

.ellogo1:hover {
    transform: scale(1.1);
}

.ipoh2 {
    padding: 6px;
    text-align: center;
    align-items: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #583a88;
    font-weight: 600;
    border: 3px solid #682943;
    font-weight: bolder;
    width: 600px;
    height: 60px;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
}

.ipoh2:hover {
    color: #1c105e;
}

.morefilters {
    color: #555;
    text-align: center;
    padding: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    align-items: center;
    text-decoration: solid #682943 underline;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .morefilters:hover {
    color: #e2b319;
} */

@media (max-width: 290px) and (orientation:portrait) {
    .ipoh2 {
        font-size: 19px;
    }

    .ipotext {
        font-size: 15px;
    }

    .ipotext1 {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .icon-container {
        flex-direction: column;
        /* Stack icons on top of each other */
    }

    .ellogo {
        width: 50%;
        /* Make images fill the container */
        height: auto;
        /* Maintain aspect ratio */
        margin: auto;
    }

    .ellogo1 {
        width: 50%;
        /* Make images fill the container */
        height: auto;
        /* Maintain aspect ratio */
        margin: auto;
        margin-top: 30px;
    }

    .ipotext {
        margin-top: 10px;
        margin-left: 50px;
    }

    .ipotext1 {
        position: static;
        /* Reset positioning for smaller screens */
        margin-top: 20px;
        /* Add some space between text and image */
        margin-bottom: 20px;
    }

    .ipoh2 {
        height: auto;
        width: 80%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .icon-container {
        margin: auto;
    }

    .ellogo {
        /* Make images fill the container */
        height: auto;
        /* Maintain aspect ratio */
        margin: 20px;
    }

    .ellogo1 {
        /* Make images fill the container */
        height: 170px;
        /* Maintain aspect ratio */
        margin: auto;
        margin-right: 10px;
        margin-top: 15px;
    }

    .ipotext,
    .ipotext1 {
        position: static;
        /* Reset positioning for smaller screens */
        margin-top: 10px;
        /* Add some space between text and image */
    }
}

.whatsapp-img {
    position: fixed;
    z-index: 2;
    width: 150px;
    /* Adjust this value as needed */
    height: 45px;
    /* Adjust this value as needed */
    /* Adjust this value as needed */
    right: 20px;
    /* Adjust this value as needed */
    bottom: 50px;
    /* Adjust this value as needed */
    /* box-shadow: 1px 1px 1px 1px #8f8f8f; */
}

.note {
    text-align: center;
    font-size: large;
    font-style: italic;
    text-decoration: underline;
    font-weight: 500;
    color: #682943;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
}
/* Appropriate styles for the component */
.filter-details {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-details h2 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: relative;
    text-decoration-line: underline;
    text-decoration-color: #ff7f27;
    color: rgb(37, 35, 129);
    top: 60px;
    margin: auto;
    text-align: center;
}

.information h3 {
    color: rgb(54, 54, 54);
    padding-top: 100px;
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
}

.information h4 {
    color: rgb(54, 54, 54);
    padding-bottom: 20px;
}


.information p {
    font-size: large;
    font-family: 'Times New Roman', Times, serif;
    color: #383737;
    font-style: italic;
}

.specifications h4 {
    color: rgb(54, 54, 54);
    padding-bottom: 20px;
}

.specifications ul {
    font-size: large;
    font-family: 'Times New Roman', Times, serif;
    color: #383737;
    font-style: italic;
}

.process {
    display: block;
    margin: auto;
    max-width: 90%;
}

.image-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.image-card {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    max-width: 300px;
    height: 300px;
    /* Set a specific height for the image cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    /* Center the image vertically */
    justify-content: center;
    /* Center the image horizontally */
    overflow: hidden;
    /* Ensure that the image doesn't overflow */
}

.image-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Maintain aspect ratio and cover the container */
}

@media screen and (max-width: 768px) {
    .image-card-container {
        max-width: 45%;
        /* Adjust the max-width for smaller screens */
        /* Let the height be determined by the content */
    }
}

.mgfcontainer {
    background-color: #f8f8f8;
    padding: 60px;
    border-radius: 10px;
    margin: 20px 0;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mgfcontainer h2 {
    font-size: 2rem;
    color: #333;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    line-height: 1.5;
}

.morefilters {
    color: #555;
    text-align: center;
    padding: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    align-items: center;
    text-decoration: solid #682943 underline;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
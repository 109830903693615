/* CSS for the black background and white text color in the footer */
.footer {
  text-align: center;
  background-color: black;
  color: white;
}

/* .btn-outline-success {
  justify-content: center;
  align-items: center;
  position: relative;
  top: 10px;
  left: 210px;
  width: 35px;
  font-size: 11px;
  height: 30px;
  border-color: #444;
  color: #000000;
  border-width: 1px;
} */

/* @media (max-width: 567px) {
  .btn-outline-success {
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
    left: 208px;
    width: 35px;
    font-size: 12px;
    height: 30px;
    border-color: #000000;
    color: #000000;
  }
} */

/* Add hover effect to the search button */
/* .btn-outline-success:hover {
  background-color: #000000;
  border: #ffffff;
  color: #fff;
} */

/* @media (max-width: 567px) {
  .btn-outline-success:hover {
    background-color: #000000;
    border: #ffffff;
    color: #fff;
  }
} */

/* Adjust the size of the search input */
/* .form-control {
  position: absolute;
  top: 10px;
  width: 200px;
  height: 30px;
  font-size: 16px;
} */

/* @media (max-width: 567px) {
  .form-control {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 190px;
  }
} */


/* SLIDE */

.carousel-item {
  max-height: 800px;
}

.background-image {
  height: 600px;
  width: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  z-index: 1;
}

.bgvid {
  width: 100%;
  height: 500px;
  /* Adjust the height as needed */
  margin: auto;
  margin-top: 5%;
  max-width: 100%;
  object-fit: contain;
  /* This will ensure the entire video is visible without cropping */
}


/* .background-image1 {
  height: 550px;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
} */

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .background-image {
    height: 450px;
    /* Adjust the height for smaller screens */
  }

  .bgvid {
    margin-top: 0%;
    height: 450px;
  }
}


.first {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 55%;
  padding: 20px;
  text-align: center;
  font-size: 38px;
  height: 50%;
  z-index: 1;
}

@media (min-width: 300px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 12px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}


/* Media Query for Mobile Devices */
/* Media Query for Mobile Devices */
@media (min-width: 375px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 13px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 640px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 17px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 768px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 20px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 1024px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 23px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 1280px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 27px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 1536px) {
  .first {
    width: 70%;
    /* Adjust the width for smaller screens */
    font-size: 32px;
    /* Adjust the font size for smaller screens */
    height: 35%;
    /* Adjust the height for smaller screens */
  }
}

/* FEATURED */

/* Updated CSS with white background for the body element */
.main {
  background-color: #ffffff;
  padding: 20px;
}

.container1 {
  text-align: center;
  padding: 30px;
}

.rectangle {
  text-align: center;
  color: #0a1d85;
  font-weight: bold;
  height: 50px;
  width: 400px;
  margin: 0 auto;
  padding: 0px;
  background-color: #ffffff;
  border: 2px solid #ff7f27;
}

.rectangle h4 {
  text-align: center;
  padding: 10px;
}


@media (max-width: 767px) {
  .rectangle {
    font-size: 20px;
    width: 260px;
    height: auto;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #ffffff;
  /* Set background color to white */
}

/* Updated CSS with spacing between cards */
.wrapper,
.wrapper2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 90%;
  padding: 20px;
  transition: transform 0.5s;
}

.wrapper.active {
  transform: translateX(-25%);
  /* Adjust based on the number of slides */
}

.single-item {
  min-width: 200px;
  max-height: 10.2%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
  margin-bottom: 20px;
  display: none;
  transition: transform 0.5s;
  will-change: transform;
}

.single-item.active {
  display: block;
}

.single-item .content {
  margin: 10px;
}

.single-item img {
  width: 100%;
}

.square {
  margin-top: 2%;
  height: 8.1%;
  width: 100%;
  background-color: #ff7f27;
}

.square p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  font-size: medium;
  justify-content: center;
  padding: 5px;
  font-weight: bold;
  color: #444;
}

.imgc {
  height: 89%;
  /* padding: 20px 15px 15px 15px; */
}

.single-item .content {
  text-align: center;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -12px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  opacity: 0;
  transition: ease 1000ms;
}

.single-item .content p {
  font-family: 'Montserrat', sans-serif;
  padding-top: 25%;
  letter-spacing: 3px;
}

.single-item:hover .content {
  opacity: 1;
  transform: translateY(0);
}

.single-item:hover img {
  -webkit-filter: blur(5px);
  filter: blur(10px);
}

.slider-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.slider-control {
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.slider-control.disabled {
  pointer-events: none;
  color: #ccc;
}

.slider-dots {
  text-align: center;
}

.slider-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.slider-dot.active {
  background-color: #555;
}

/* Portrait mode for Mobile */
@media (max-width: 567px) {
  .single-item {
    flex-basis: 100%;
    margin: 1%;
    margin-bottom: 8%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    font-size: medium;
    justify-content: center;
    padding-top: 2.5%;
    margin: auto;
    font-weight: bold;
    color: #444;
  }
}

@media (min-width: 568px) and (max-width: 766px) {
  .single-item {
    flex-basis: 48%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    font-size: medium;
    padding-top: 3.3%;
    justify-content: center;
    font-weight: bold;
    margin: auto;
    color: #444;
  }
}


/* Landscape mode for Mobile */
@media (max-width: 950px) and (orientation: landscape) {
  .single-item {
    flex-basis: 48%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    font-size: medium;
    padding-top: 3.3%;
    justify-content: center;
    margin: auto;
    font-weight: bold;
    color: #444;
  }
}

/* Tablet in portrait and landscape */
@media (min-width: 768px) and (max-width: 991px) {
  .single-item {
    flex-basis: 48%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    font-size: medium;
    justify-content: center;
    margin: auto;
    font-weight: bold;
    color: #444;
  }
}

/* PC */
@media (min-width: 992px) and (max-width: 1200px) {
  .single-item {
    flex-basis: 48%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 3.5%;
    margin: auto;
    font-size: medium;
    justify-content: center;
    font-weight: bold;
    color: #444;
  }
}

@media (min-width: 1200px) and (max-width: 1550px) {
  .single-item {
    flex-basis: 23%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 3.3%;
    margin: auto;
    font-size: medium;
    justify-content: center;
    font-weight: bold;
    color: #444;
  }
}

@media (min-width: 1551px) {
  .single-item {
    flex-basis: 23%;
    margin: 1%;
  }

  .square p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 3.3%;
    margin: auto;
    font-size: medium;
    justify-content: center;
    font-weight: bold;
    color: #444;
  }
}


/* FOOTER */

/* Footer styles */

footer {
  background-color: #000000;
  color: #fff;
  padding: 20px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-logo img {
  max-width: 100px;
  /* Adjust the logo size as needed */
}

.footer-column {
  flex-basis: calc(20% - 20px);
  /* Four columns with spacing */
  text-align: left;
  margin-right: 10px;
  /* Reduce right margin */
  margin-bottom: 20px;
}

.footer-column h4 {
  color: #ff7f27;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-column p {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10.3pt;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

/* Adjust the margin for social icons */
.social-icons {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
  /* Reduced top margin */
  display: flex;
  /* Display the social icons inline */
}

.social-icons li {
  margin-right: 10px;
}

.social-icons a {
  color: #c2a00b;
  text-decoration: none;
  font-size: 24px;
}

.footer-copyright {
  text-align: center;
  clear: both;
  padding-top: 20px;
}

/* Style for the footer divider */
.footer-divider {
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  width: 70%;
  height: 1px;
  color: #ff7f27;
  /* Adjust the width as needed */
  background-color: #ff7f27;
  /* Divider color */
}





@media screen and (max-width: 867px) {
  .footer-content {
    flex-direction: column;
    /* Stack columns vertically */
    margin-left: 15px;
  }

  .footer-column {
    flex-basis: 100%;
    /* Full width for each column on smaller screens */
    margin-right: 0;
    /* Remove right margin */
  }

  .social-icons {
    margin-top: 10px;
    /* Add margin to social icons for spacing */
  }

  .footer-logo {
    padding-bottom: 20px;
  }

}

/* contact */
.mapcontainer {
  position: relative;
  width: 100%;
  height: 50vh;
  margin-top: 5px;
  margin-bottom: 10px;
  /* 50% of the viewport height */
}

.mapcontainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.custom {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-style: italic;
  color: rgb(65, 57, 57);
  text-align: center;
  padding: 10px;
  font-size: xx-large;
  padding-bottom: 20px;
}
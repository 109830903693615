/* Center-align the image within the logo container */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Ensure the logo container takes the full height of the navbar */
}

.logo {
    top: 2px;
    width: 75px;
    right: 10px;
}

.logo2 {
    width: 200px;
}

@media (max-width: 290px) and (orientation:portrait) {
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }

    .navbar-toggler {
        width: 30px;
        height: 25px;
    }

    .navbar-toggler-icon {
        height: 15px;
        width: 15px;
        margin-bottom: 10px;
        margin-left: -5px;
    }
}

@media(min-width:291px) and (max-width: 370px) and (orientation:portrait) {


    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }

    .navbar-toggler {
        width: 40px;
        height: 35px;
    }

    .navbar-toggler-icon {
        height: 25px;
        width: 25px;
        margin-bottom: 10px;
        margin-left: -5px;
    }
}


/* Center-align the navbar content within the navbar */
.navbar {
    position: relative;
    display: flex;
    background-color: #ff7f27;
    height: 85px;
    padding: 20px;
    width: 100%;
    z-index: 2;
    /* Remove the comma and set padding as a single value */
}

.toggletext a:hover {
    color: #ff7f27;
    /* Change to your desired hover color */
}


.navbar-toggler {
    position: relative;
    left: 10px;
    top: 0px;
}

/* .navbar-nav {
    position: relative;
    left: 850px;
    font-weight: bolder;
  } */

@media (min-width: 1550px) {
    .navbar-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bolder;
        z-index: 2;
    }

    .navbar-nav .nav-item:nth-child(2) {
        margin-right: 120px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(3) {
        position: relative;
        left: 40px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(4) {
        position: relative;
        left: 35px;
        /* Adjust the margin as needed */
    }

    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }
}

@media (min-width: 1500px) and (max-width:1549px) {
    .navbar-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bolder;
        z-index: 2;
    }

    .navbar-nav .nav-item:nth-child(2) {
        margin-right: 120px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(3) {
        position: relative;
        left: 40px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(4) {
        position: relative;
        left: 35px;
        /* Adjust the margin as needed */
    }

    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .navbar-nav {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bolder;
        z-index: 2;
    }

    .navbar-nav .nav-item:nth-child(2) {
        margin-right: 120px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(3) {
        position: relative;
        left: 40px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(4) {
        position: relative;
        left: 35px;
        /* Adjust the margin as needed */
    }

    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }
}


/* .d-flex {
    position: relative;
    left: 20px;
  } */

@media (min-width: 1000px) and (max-width: 1199px) {
    .navbar-nav {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bolder;
        z-index: 2;
    }

    .navbar-nav .nav-item:nth-child(2) {
        margin-right: 120px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(3) {
        position: relative;
        left: 40px;
        /* Adjust the margin as needed */
    }

    .navbar-nav .nav-item:nth-child(4) {
        position: relative;
        text-align: center;
        left: 65px;
        /* Adjust the margin as needed */
    }

    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }
}


@media (min-width: 768px) and (max-width: 999px) {
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }

    .navbar-nav {
        position: relative;
        left: 10%;
        margin: auto;
        width: fit-content;
    }

    .navbar-toggler {
        display: none;
    }

    /* .navbar-nav {
      position: relative;
      left: 0;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    } */

    .navbar-toggler {
        display: block;
    }

    .sidebar {
        position: relative;
        left: 0;
        top: 0;
    }

    .sidebar ul {
        list-style: none;
        padding: 0;
    }

    .sidebar li {
        margin: 0;
        padding: 10px 0;
    }

    .nav-item {
        margin: 0 8px;
    }
}

@media (max-width: 767px) {
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        width: 150px;
        height: auto;
        z-index: 2;
        border: 15px solid #ff7f27;
        border-radius: 0 0 500px 500px;
    }

    .logo2 {
        display: none;
    }

    .navbar-nav {
        position: relative;
        left: 0;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .navbar-toggler {
        display: block;
    }

    .sidebar {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
    }

    .sidebar ul {
        list-style: none;
        padding: 0;
    }

    .sidebar li {
        margin: 0;
        padding: 10px 0;
    }
}

.text {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin: 18px;
}

.text:hover {
    color: #555;
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    /* Start off-canvas */
    width: 250px;
    height: 120%;
    /* Adjusted height */
    max-height: 100%;
    background-color: #484f5c;
    color: white;
    font-style: normal;
    transition: left 0.4s ease-in-out;
    /* Smooth transition when opening/closing */
    padding-top: 60px;
    /* Adjust to match your top navbar height */
    z-index: 2;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* Added scrollbars */
}

.dropdown-toggle::after {
    display: none;
}


.sidebar.open {
    left: 0;
    /* Slide in when open */
}

.sidebar ul {
    position: relative;
    bottom: 30px;
    list-style: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
}

/* Style sidebar links */
.sidebar a {
    font-size: 20px;
    text-decoration: double;
    color: rgb(255, 255, 255);
}

/* Style sidebar dropdowns */
.sidebar .dropdown {
    font-size: larger;
    position: relative;
}

/* .sidebar .dropdown:hover {
    color: #ffd000;
  } */


/* .sidebar .dropdown-toggle::after {
    content: "\f0d7";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  } */

/* Style sidebar dropdown menus */
.sidebar .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #d67200;
    margin-left: 10px;
    width: 90%;
}

.sidebar .dropdown:hover .dropdown-menu {
    display: block;
    /* Show dropdown on hover */
}

.sidebar .dropdown-menu a {
    padding-left: 10px;
    background-color: #d67200;
    display: block;
    color: rgb(255, 255, 255);
    margin: auto;
}

.sidebar .dropdown-menu a:hover {
    background-color: #000000;
    border-radius: 5px;
    /* padding: 10px; */
}


/* .sidebar .dropdown-menu hr {
    position: relative;
    border: none;
    height: 3px;
    background: rgb(0, 0, 0);
  
  } */

.sideBarContents {
    font-size: smaller;
}





.nav-link.dropdown-item {
    font-family: 'Times New Roman', Times, serif;
    color: #ffffff;
    background-color: #414141;
    margin-top: -10px;
    margin-bottom: -10px;
    border: 3px solid #414141;
}

.nav-link.dropdown-item:hover {
    background-color: #ffffff;
    color: #ff7f27;
    border: 3px solid #ffffff;
    border-bottom: 5px solid #ffffff;
}

/* Add this CSS to match the text size and hover effect */
.navbar-nav .nav-link.dropdown-toggle {
    color: #ffffff;
    font-size: 20px;
    /* Adjust as needed */
}

.navbar-nav .nav-link.dropdown-toggle:hover {
    color: #555;
    /* Adjust hover color */
}

.navbar-expand-lg .navbar-nav {
    justify-content: flex-end;
}